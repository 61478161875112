
















































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import MultiselectV2 from '@/app/ui/components/MultiselectV2/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'
import ModalConfirm from '../../../components/Modals/ModalConfirm/index.vue'
import ModalSuccess from '../../../components/Modals/ModalSuccess/index.vue'
import {
  EnumCourierTypeParams,
  EnumOfferMethodType,
  EnumStatus,
  IForm,
  IOptions,
  UpdatePayload,
} from '@/app/infrastructures/misc/Constants/manageCourierDedicated'
import NestedMultiSelect, {
  ILocation,
} from '../../../components/NestedMultiSelect/index.vue'
import dedicatedController from '@/app/ui/controllers/ManageCourierDedicatedController'
import courierController from '@/app/ui/controllers/ManageCourierController'
import {
  GeoAddress,
  ManageCourierDedicatedDetail,
} from '@/domain/entities/ManageCourierDedicated'
import { Utils } from '@/app/infrastructures/misc'
import { ManageCourierAdjustSaldo } from '@/domain/entities/ManageCourier'

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    DropdownSelect,
    TextInput,
    DateTimePicker,
    MultiselectV2,
    LoadingOverlay,
    IconWarningTriangle,
    ModalConfirm,
    ModalSuccess,
    NestedMultiSelect,
  },
})
export default class EditAreaDedicatedPage extends Vue {
  dedicatedController = dedicatedController
  courierController = courierController
  modalConfirmVisible = false
  modalSuccessVisible = false
  courierOptionsMP: Array<IOptions> = []
  courierOptionsCA: Array<IOptions> = []
  courierOptionsClient: Array<IOptions> = []
  geoAddressMainList: Array<ILocation> = []
  existingAreaIds: Array<number> = []

  offerMethodOptions: Array<IOptions> = [
    {
      label: EnumOfferMethodType.AUTO_ASSIGN,
      value: EnumOfferMethodType.AUTO_ASSIGN,
    },
    { label: EnumOfferMethodType.OFFER, value: EnumOfferMethodType.OFFER },
  ]

  statusOptions: Array<IOptions> = [
    { label: EnumStatus.AKTIF, value: true },
    { label: EnumStatus.NONAKTIF, value: false },
  ]

  hasChanged: Record<string, boolean> = {
    areaName: false,
    location: false,
    offerMethod: false,
    status: false,
  }

  courierParameters = {
    page: 1,
    perPage: 10,
    q: '',
    sortBy: 'ASC',
    driverType: '',
    courierStatus: 'ACTIVE',
  }

  geoAddressParameters = {
    search: '',
  }

  form: IForm = {
    areaName: '',
    location: [],
    courierMP: [],
    courierCA: [],
    courierClient: [],
    offerMethod: this.offerMethodOptions[-1],
    status: this.statusOptions[-1],
  }

  created(): void {
    this.fetchAreaDedicatedDetail()
    this.fetchMaxConfig()
    this.fetchCourier(true, EnumCourierTypeParams.POS)
  }

  get courierParams(): Record<string, string | number> {
    return { ...this.courierParameters }
  }

  get geoAddressParams(): Record<string, string> {
    return { ...this.geoAddressParameters }
  }

  private fetchMaxConfig(): void {
    this.dedicatedController.getMaxConfig()
  }

  private fetchGeoAddresses(): void {
    this.dedicatedController.getGeoAddress(this.geoAddressParams)
  }

  private fetchCourier(reset?: boolean, driverType?: EnumCourierTypeParams): void {
    if (reset) this.courierParameters.page = 1

    if (driverType === EnumCourierTypeParams.POS) {
      this.courierParameters.driverType = EnumCourierTypeParams.POS
    } else if (driverType === EnumCourierTypeParams.KVP) {
      this.courierParameters.driverType = EnumCourierTypeParams.KVP
    }

    this.courierController.getManageCourierListAdjustSaldo(this.courierParams)
  }

  private fetchAreaDedicatedDetail(): void {
    this.dedicatedController.getDetail(Number(this.$route.params.id))
  }

  private filterCourier = Utils.debounce(() => {
    this.fetchCourier(true, EnumCourierTypeParams.POS)
  }, 400)

  private onAddCourier(field: 'courierMP' | 'courierCA'): void {
    this.form[field].push(this.courierOptionsMP[-1])
  }

  private onDeleteCourier(
    field: 'courierMP' | 'courierCA',
    index: number
  ): void {
    this.form[field].splice(index, 1)
  }

  private onBack(): void {
    this.modalSuccessVisible = false
    this.$router.push('/offer/manage-courier-dedicated')
  }

  private onInputAreaName(): void {
    this.hasChanged.name = true
    dedicatedController.setIsAreaNameExist(false)
  }

  private onChangeMultiselect(value: Array<number>): void {
    this.hasChanged.location = true
    this.form.location = value
  }

  private onCourierSearch(query: string): void {
    this.courierParameters.q = query
    this.filterCourier()
  }

  private onSubmit(): void {
    this.modalConfirmVisible = false
    let geoIds: Array<number> = []

    this.geoAddressMainList.map(item => {
      if (item.getGeoAddressIds().length !== 0) {
        geoIds.push(...item.getGeoAddressIds().map(id => Number(id)))
      }
    })

    if (!this.$v.form.$invalid) {
      const payload: UpdatePayload = {
        id: Number(this.$route.params.id),
        area_name: this.form.areaName.trim(),
        geo_address_id: this.form.location,
        courier_mp: this.form.courierMP.map(courier => <number>courier.value),
        courier_ca: this.form.courierCA.map(courier => <number>courier.value),
        courier_client: this.form.courierClient.map((courier) => <number>courier.value),
        offer_type: <string>this.form.offerMethod.value,
        is_active: <boolean>this.form.status.value,
      }
      this.dedicatedController.update(payload)
    } 
  }

  private locationMapper(
    data: GeoAddress,
    existingIds: Array<number>
  ): ILocation {
    return {
      name: data.name || '',
      isChecked: false,
      geoAddressId: data.geoAddressId ? String(data.geoAddressId) : '',
      isParentNotHidden: false,
      setParentNotHidden(state: boolean): void {
        this.children.forEach((child) => {
          child.isParentNotHidden = state
          child.setParentNotHidden(state)
        })
      },
      isNotHidden(keyword: string): boolean {
        const find = (item: ILocation) => item.isNotHidden(keyword)
 
        if (this.name.toUpperCase().includes(keyword.toUpperCase())) {
          this.setParentNotHidden(true)
          return true
        } else {
          if (this.isParentNotHidden) {
            return true
          } else {
            this.setParentNotHidden(false)
          } 
        }
 
        return (
          this.isParentNotHidden ||
          this.name.toUpperCase().includes(keyword.toUpperCase()) ||
          (this.children && this.children.some(find))
        )
      },
      isDisabled(): boolean {
        if (
          data.areaId &&
          data.geoAddressId &&
          data.areaId !== 0 &&
          !existingIds.includes(Number(data.geoAddressId))
        ) {
          return true
        } else if (data.children && data.children.length !== 0) {
          let childDisabledCount = 0

          this.children.forEach(child => {
            if (child.isDisabled()) {
              childDisabledCount++
            }
          })

          if (childDisabledCount === this.children.length) {
            return true
          }
        }

        return false
      },
      getGeoAddressIds(): Array<number> {
        if (this.isChecked && !this.isDisabled()) {
          if (data.geoAddressId && data.geoAddressId !== 0) {
            return [data.geoAddressId]
          } else if (data.children && data.children.length !== 0) {
            let geoIds: Array<number> = []

            this.children.forEach(child => {
              geoIds.push(...child.getGeoAddressIds())
            })

            return geoIds
          }
        } else if (data.children && data.children.length !== 0) {
          let geoIds: Array<number> = []

          this.children.forEach(child => {
            geoIds.push(...child.getGeoAddressIds())
          })

          return geoIds
        }

        return []
      },
      setIsChecked(state: boolean): void {
        this.isChecked = state
      },
      setCheckedNested(state: boolean): void {
        this.isChecked = state

        if (this.children.length !== 0) {
          this.children.forEach(item => {
            item.setCheckedNested(state)
          })
        }
      },
      children: data.children
        ? data.children.map(child => {
            return this.locationMapper(child, existingIds)
          })
        : [],
    }
  }

  private setLocationState(
    locData: Array<ILocation>,
    idList: Array<number>
  ): void {
    locData.forEach(location => {
      if (
        location.geoAddressId &&
        idList.includes(Number(location.geoAddressId))
      ) {
        location.setIsChecked(true)

        if (location.children.length !== 0) {
          location.setCheckedNested(true)
        }
      }

      if (location.children.length !== 0) {
        this.setLocationState(location.children, idList)
      }
    })
  }

  private checkSelectedOption(data: IOptions): boolean {
    return !(data === undefined || data.value === undefined)
  }

  @Validations()
  validations(): Record<string, unknown> {
    return {
      form: {
        areaName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
        },
        location: { required },
        offerMethod: {
          isNotEmpty: (value: IOptions) => this.checkSelectedOption(value),
        },
        status: {
          isNotEmpty: (value: IOptions) => this.checkSelectedOption(value),
        },
      },
    }
  }

  @Watch('courierController.manageCourierAdjustSaldoData')
  setManageCourierData(data: Array<ManageCourierAdjustSaldo>): void {
    const resultMP: Array<{ label: string; value: number }> = []
    const resultCA: Array<{ label: string; value: number }> = []
    const resultClient: Array<{ label: string; value: number }> = []
    let existingIdsMP: Array<number> = []
    let existingIdsCA: Array<number> = []
    let existingIdsClient: Array<number> = []

    this.form.courierMP.forEach(item => {
      if (item) existingIdsMP.push(<number>item.value)
    })

    this.form.courierCA.forEach(item => {
      if (item) existingIdsCA.push(<number>item.value)
    })

    this.form.courierClient.forEach(item => {
      if (item) existingIdsClient.push(<number>item.value)
    })

    data.forEach((courier: ManageCourierAdjustSaldo) => {
      if (courier.courierId && !existingIdsMP.includes(courier.courierId)) {
        const label = `[${courier.courierId}] ${courier.fullName} (${courier.announcementCourierType}) ${courier.phoneNumber} • ${courier.partnerName}`
        const value = courier.courierId

        if (label && value) {
          resultMP.push({
            label: label,
            value: value,
          })
        }
      }

      if (courier.courierId && !existingIdsCA.includes(courier.courierId)) {
        const label = `[${courier.courierId}] ${courier.fullName} (${courier.announcementCourierType}) ${courier.phoneNumber} • ${courier.partnerName}`
        const value = courier.courierId

        if (label && value) {
          resultCA.push({
            label: label,
            value: value,
          })
        }
      }

      if (courier.courierId && !existingIdsClient.includes(courier.courierId)) {
        const label = `[${courier.courierId}] ${courier.fullName} (${courier.announcementCourierType}) ${courier.phoneNumber} • ${courier.partnerName}`
        const value = courier.courierId

        if (label && value) {
          resultClient.push({
            label: label,
            value: value,
          })
        }
      }
    })

    if (this.courierParameters.driverType === EnumCourierTypeParams.POS) {
      this.courierOptionsMP = resultMP
      this.courierOptionsCA = resultCA
      this.courierOptionsClient = resultClient
      this.fetchCourier(true, EnumCourierTypeParams.KVP)
    } else if (
      this.courierParameters.driverType === EnumCourierTypeParams.KVP
    ) {
      this.courierOptionsMP.push(...resultMP)
      this.courierOptionsCA.push(...resultCA)
      this.courierOptionsClient.push(...resultClient)
      this.courierParameters.driverType = ''
    }
  }

  @Watch('form.courierMP')
  onCheckCourierMP() {
    this.fetchCourier(true, EnumCourierTypeParams.POS)
  }

  @Watch('form.courierCA')
  onCheckCourierCA() {
    this.fetchCourier(true, EnumCourierTypeParams.POS)
  }

  @Watch('form.courierClient')
  onCheckCourierClient() {
    this.fetchCourier(true, EnumCourierTypeParams.POS)
  }

  @Watch('dedicatedController.dataGeoAddressList')
  setGeoAddressData(data: Array<GeoAddress>): void {
    if (data) {
      const mapRes: Array<ILocation> = data.map(
        (item): ILocation => {
          return this.locationMapper(item, this.existingAreaIds)
        }
      )

      this.geoAddressMainList = mapRes

      this.setLocationState(this.geoAddressMainList, this.form.location)
    }
  }

  @Watch('dedicatedController.dataManageCourierDedicatedDetail')
  setDedicatedDetailData(data: ManageCourierDedicatedDetail): void {
    let courierMp: Array<IOptions> = []
    let courierCa: Array<IOptions> = []
    let courierClient: Array<IOptions> = []

    if (data.courierMp) {
      courierMp = data.courierMp.map(courier => {
        return {
          label: `[${courier.courierId}] ${courier.name} (${courier.courierType}) ${courier.phone} • ${courier.partnerName}`,
          value: courier.courierId,
        }
      })
    }

    if (data.courierCa) {
      courierCa = data.courierCa.map(courier => {
        return {
          label: `[${courier.courierId}] ${courier.name} (${courier.courierType}) ${courier.phone} • ${courier.partnerName}`,
          value: courier.courierId,
        }
      })
    }

    if (data.courierCorporate) {
      courierClient = data.courierCorporate.map(courier => {
        return {
          label: `[${courier.courierId}] ${courier.name} (${courier.courierType}) ${courier.phone} • ${courier.partnerName}`,
          value: courier.courierId,
        }
      })
    }


    const res: IForm = {
      areaName: data.areaName || '',
      location: data.geoAddressIds ? data.geoAddressIds : [],
      courierMP: courierMp,
      courierCA: courierCa,
      courierClient: courierClient,
      offerMethod:
        this.offerMethodOptions.find(item => item.value === data.offerType) ||
        this.offerMethodOptions[-1],
      status: data.isActive ? this.statusOptions[0] : this.statusOptions[1],
    }

    this.form = res

    if (data.geoAddressIds) {
      this.existingAreaIds = data.geoAddressIds
      this.form.location = data.geoAddressIds
    }

    this.fetchGeoAddresses()
  }

  @Watch('form.areaName')
  onChangeAreaName(data: string): void {
    // remove script tags
    this.form.areaName = Utils.removeScriptTags(data)

    // remove special characters
    this.form.areaName = Utils.removeSpecialCharacters(this.form.areaName)

    // remove html tags
    this.form.areaName = Utils.removeHTMLTags(this.form.areaName)
  }

  @Watch('dedicatedController.isSuccessEditManageCourierDedicated')
  onSuccessEdit(value: boolean): void {
    if (value) {
      this.modalSuccessVisible = true
    }
  }

  beforeDestroy(): void {
    dedicatedController.setIsAreaNameExist(false)
    dedicatedController.setIsSuccessEditManageCourierDedicated(false)
  }
}
